<template>
  <CommentProcView>
    <template v-slot:title>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          Просмотр тех. процесса
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:actions>
      <v-card-actions>
        <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
      </v-card-actions>
    </template>
  </CommentProcView>
</template>

<script>
import CommentProcView from './components/CommentProcView.vue'

export default {
  components: { CommentProcView },
}
</script>
